var localizedDetails = function() {
	var color = "colour";	
	var colorCap = "Colour";
	var invalidQuestionMsg = "Questions need to be longer than 5 characters";
	var invalidAnswerMsg = "Answers must be longer than 1 character";
	var phoneNumber = '0800 055 6196';
	var vatTax = function (extrasTotal, total, ukTaxRate) {		
		return (parseFloat(ukTaxRate) * (parseFloat(total) + extrasTotal)).toFixed(2);				 					
	};
	
	var totalWithVat = function(extrasTotal, total, ukTaxRate){	
		var vat = vatTax(extrasTotal, total, ukTaxRate);
		return  (parseFloat(vat) + parseFloat(total) + parseFloat(extrasTotal)).toFixed(2);
	}	
	
	var showCustomerImages = false;
	var showSuccessStories = false;
	
	let localeName = "UK"; 
	let regSM = "SM"; 
	let certainDeliveryParagraph = "It's on time or it's on us. If your event is missed because we didn't dispatch on time, your order is free.";
	let certainHappinessParagraph = "100% satisfaction guaranteed, your goods will be printed exactly as per your artwork proof, or we'll reprint your order or send you a refund.";

	return {
		Color: color,
		InvalidQuestionMsg: invalidQuestionMsg,
		InvalidAnswerMsg: invalidAnswerMsg,
		ColorCap: colorCap,
		PhoneNumber: phoneNumber,
		VatTax: vatTax,
		TotalWithVat: totalWithVat,
		showCustomerImages: showCustomerImages,
		showSuccessStories: showSuccessStories,
		LocaleName: localeName,
		RegSM: regSM,
		CertainDeliveryParagraph: certainDeliveryParagraph,
		CertainHappinessParagraph: certainHappinessParagraph
	};
};